import type { GlobalFormErrorLabels2 } from '../../amplienceTypes/schemas/imported/global-form-error-labels-schema';
import type { Pattern, Required } from '../../components/shared/core/form/types';
import { emailRegex } from './regex';

export const getFormSchema = ({
  emailRequiredLabel,
  emailInvalidLabel,
  preferencesRequiredLabel,
  notificationRequiredLabel,
  gridValue1RequiredLabel,
  gridValue2RequiredLabel,
  nameRequiredLabel,
  subscriptionRequiredLabel,
}: GlobalFormErrorLabels2) => ({
  email: {
    required: {
      value: true,
      message: emailRequiredLabel,
    } as Required,
    pattern: {
      value: emailRegex,
      message: emailInvalidLabel,
    } as Pattern,
  },
  preferences: {
    required: {
      value: true,
      message: preferencesRequiredLabel,
    } as Required,
  },
  notification: {
    required: {
      value: true,
      message: notificationRequiredLabel,
    } as Required,
  },
  gridValue1: {
    required: {
      value: true,
      message: gridValue1RequiredLabel,
    } as Required,
  },
  gridValue2: {
    required: {
      value: true,
      message: gridValue2RequiredLabel,
    } as Required,
  },
  name: {
    required: {
      value: true,
      message: nameRequiredLabel,
    } as Required,
  },
  subscribed: {
    required: {
      value: true,
      message: subscriptionRequiredLabel,
    } as Required,
  },
});
