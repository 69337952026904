/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactNode } from 'react';
import { createContext, useContext, useState, useMemo } from 'react';

import { useStaticContext } from '../static/StaticContext';
import type { UseComponentSizeState } from '../../dom';

type SearchContextType = {
  hasSearchV2?: boolean;
  isSearchBoxOpen: boolean;
  setIsSearchBoxOpen: (isSearchBoxOpen: boolean) => void;
  searchToggleSize: UseComponentSizeState;
  setSearchToggleSize: (size: UseComponentSizeState) => void;
};

const SearchContext = createContext<SearchContextType>({
  hasSearchV2: undefined,
  isSearchBoxOpen: false,
  setIsSearchBoxOpen: () => {},
  searchToggleSize: { top: 0, left: 0, width: 0, height: 0 },
  setSearchToggleSize: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

export const SearchContextProvider = ({ children }: { children: ReactNode }) => {
  const {
    configuration: { searchV2 },
  } = useStaticContext();

  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState<boolean>(false);
  const [searchToggleSize, setSearchToggleSize] = useState<UseComponentSizeState>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const value = useMemo(
    () => ({
      hasSearchV2: Boolean(searchV2),
      isSearchBoxOpen,
      setIsSearchBoxOpen,
      searchToggleSize,
      setSearchToggleSize,
    }),
    [searchV2, isSearchBoxOpen, searchToggleSize]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
