/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';
import type { Cart } from '../../graphql/codegen';
import { fetchCartData } from '../../graphql/queries';
import {
  CART_ENTRIES,
  CART_MUTATED,
  HYBRIS_CART_STORAGE_VALUE,
} from '../../constants/localStorageKeys';
import { useAppContext } from '../static/AppContext';
import { storageAvailable } from '../../helpers';
import { triggerQubitEvent } from '../../vendors';
import { useStaticContext } from '../static/StaticContext';

type CartContextType = {
  miniCart: Cart;
  setMiniCart: (miniCart: Cart) => void;
  cartHovered: boolean;
  setCartHovered: (cartHovered: boolean) => void;
};

const CartContext = createContext<CartContextType>({
  miniCart: {},
  setMiniCart: () => {},
  cartHovered: false,
  setCartHovered: () => {},
});

export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const { locale, country } = useAppContext();
  const {
    configuration: { enableCookieWall },
  } = useStaticContext();
  const [cartHovered, setCartHovered] = useState(false);
  const [miniCart, setMiniCart] = useState<Cart>({});

  useEffect(() => {
    const fetchCart = async () => {
      let cart: Cart | null = null;
      let toFetchCart = false;

      if (typeof window !== 'undefined') {
        const isLocalStorageAvailable = storageAvailable('localStorage');
        const cartMutated = isLocalStorageAvailable
          ? localStorage.getItem(`${country}${CART_MUTATED}`)
          : '';
        const localCartData = isLocalStorageAvailable
          ? localStorage.getItem(`${CART_ENTRIES}_${locale}`)
          : '';

        if (cartMutated === HYBRIS_CART_STORAGE_VALUE) {
          toFetchCart = true;

          if (isLocalStorageAvailable) {
            localStorage.removeItem(`${country}${CART_MUTATED}`);
          }
        } else if (localCartData) {
          cart = JSON.parse(localCartData);
          setMiniCart(JSON.parse(localCartData));
        } else {
          toFetchCart = true;
        }

        if (toFetchCart) {
          const cartData = await fetchCartData(locale);

          if (cartData?.cart) {
            cart = cartData.cart;
            setMiniCart(cartData.cart);

            if (isLocalStorageAvailable) {
              localStorage.setItem(`${CART_ENTRIES}_${locale}`, JSON.stringify(cartData.cart));
            }
          }
        }

        if (cart?.entries?.length) {
          triggerQubitEvent('ecBasketSummary', cart, enableCookieWall);
          cart.entries?.forEach(entry => {
            if (entry) {
              triggerQubitEvent('ecBasketItem', { cart, cartEntry: entry }, enableCookieWall);
            }
          });
        }
      }
    };

    fetchCart();
  }, [locale, country, enableCookieWall]);

  const value = useMemo(
    () => ({
      miniCart,
      setMiniCart,
      cartHovered,
      setCartHovered,
    }),
    [miniCart, cartHovered]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
