import { forwardRef } from 'react';
import type { ReactNode, ReactElement, Ref } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

interface NextLinkProps {
  href: string;
  children?: ReactNode;
  className?: string;
  blank?: boolean;
  testId?: string;
  ariaLabel?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const S = {
  Anchor: styled.a`
    text-decoration: none;
  `,
};

const NextLinkComponent = (
  {
    href,
    children,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    testId = undefined,
    blank,
    ariaLabel = '',
    ...rest
  }: NextLinkProps,
  ref: Ref<HTMLAnchorElement>
): ReactElement => (
  <Link ref={ref} href={href} passHref legacyBehavior>
    <S.Anchor
      className={className}
      data-testid={testId}
      aria-label={ariaLabel}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target={blank ? '_blank' : '_self'}
      {...rest}
    >
      {children}
    </S.Anchor>
  </Link>
);

export const NextLink = forwardRef<HTMLAnchorElement, NextLinkProps>(NextLinkComponent);
