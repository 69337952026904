import { Environments } from '../constants';
import { defaultLocales } from '../constants/defaultLocales';
import type { QubitResponse, ExperiencePayload } from '../../types/qubit';
import {
  PRODUCT_CODE_REGEXP,
  LEAN_PRODUCT_CODE_REGEXP,
  COMBINABLE_LOCALE_REGEX,
  LEAN_LOCALE_REGEX,
} from '../constants/regex';

export function isPDP(path: string) {
  const shopWithProductCodeRegexp = new RegExp(
    `^${COMBINABLE_LOCALE_REGEX.source}/shop/(.*/)?${LEAN_PRODUCT_CODE_REGEXP.source}(\\?.*)?$`,
    'i'
  );

  return shopWithProductCodeRegexp.test(path);
}

export function isPLP(path: string) {
  const shopRegexp = new RegExp(`^${COMBINABLE_LOCALE_REGEX.source}/shop(/.*)?`, 'g');

  return shopRegexp.test(path) && !isPDP(path);
}

export function isSearch(path: string) {
  const searchRegexp = new RegExp(`^${COMBINABLE_LOCALE_REGEX.source}/search(\\?.*)?$`, 'g');

  return searchRegexp.test(path);
}

export function isValidProductCode(productCode: string): boolean {
  return PRODUCT_CODE_REGEXP.test(productCode);
}

export function getProductCode(url: string): string | null {
  const productCode = url.split('/').at(-1)?.split('?').at(0);

  if (productCode && isValidProductCode(productCode)) {
    return productCode;
  }

  return null;
}

export function getCategoryPathsFromPDPUrl(pdpUrl?: string | null) {
  const paths = pdpUrl?.split('/') || [];

  if (pdpUrl?.length && LEAN_LOCALE_REGEX.test(pdpUrl)) {
    paths.splice(1, 1);
  }

  const superCategory = paths[2];
  const pageCategory = paths[3];
  const plpPath = paths.slice(0, -1).join('/');

  return { superCategory, pageCategory, plpPath };
}

export function isValidLocale(locale: string): boolean {
  const locales = `${process.env.LOCALES}`.split(',');
  const defaultIndex = Number(process.env.DEFAULT_LOCALE_INDEX);

  if (Array.isArray(locales)) {
    const localeIndex = locales.indexOf(locale.toLocaleLowerCase());

    return localeIndex >= 0 && localeIndex !== defaultIndex;
  }

  return false;
}

export function isMatchLocaleCountry(ipLocale: string, locale: string): boolean {
  const ipLocaleCountry = ipLocale.split('_')[1];
  const localeCountry = locale.split('_')[1];

  return ipLocaleCountry === localeCountry;
}

export function getCountryCodeFromAkamaiHeader(akamaiEdgescape: string | null): string | null {
  try {
    const obj = JSON.parse(`{"${akamaiEdgescape}"}`.replace(/=/g, '":"').replace(/,/g, '","'));

    return obj.country_code?.toLowerCase();
  } catch (e) {
    return null;
  }
}

export function getDefaultLocaleFromCountryCode(countryCode: string | null | undefined): string {
  return defaultLocales[countryCode?.toLowerCase() || ''] || 'default';
}

/**
 * Not all Hybris given Invalidation Page Types are applicable for storefront.
 *
 * Possible values from Hybris cache invalidation are:
 * PLP
 * PDP
 * PDP_DATA
 * HOME
 * CONTENT
 * SYSTEM_TEXTS
 * DYNAMIC_FORM
 * VALIDATION_RULES
 * CONFIGURATION
 * COUNTRIES
 * PRODUCT_EXPERIENCE
 * PRODUCT
 * PAYMENT_METHODS
 * SOCIAL_MEDIA_CHANNELS
 * SEARCH_SUGGESTIONS
 * CHECKOUT
 * ORDER_CONFIRMATION_PAGE
 * LOYALTY
 * PRODUCT_USP
 */
export function isInvalidationPageTypeApplicable(invalidationPageType: string): boolean {
  return [
    '.*',
    'ALL',
    'PDP',
    'PLP',
    'PRODUCT',
    'SOCIAL_MEDIA_CHANNELS',
    'PAYMENT_METHODS',
    'CONFIGURATION',
    'CONTENT',
    'HOME',
  ].includes(invalidationPageType);
}

/**
 * Translates "(?i)(en-NL|nl-NL)" which is Java specific to
 * `new RegExp('(en_NL|nl_NL)', 'i')`
 */
export function translateRegExp(fromHybris: string): RegExp {
  return new RegExp(fromHybris.replaceAll('(?i)', '').replaceAll('-', '_'), 'i');
}

/**
 * Create a function to easily check if a given
 * file path should be deleted/unlinked based on
 * given cache invalidation criteria.
 */
export function createCheckUnlinkable(
  path: string,
  contentLanguage: string,
  invalidationPageType: string
) {
  const isMatchPDP = ['PDP', 'PRODUCT'].includes(invalidationPageType);
  const isMatchPLP = ['PLP'].includes(invalidationPageType);
  const isMatchGlobal = [
    '.*',
    'ALL',
    'SOCIAL_MEDIA_CHANNELS',
    'PAYMENT_METHODS',
    'CONFIGURATION',
    'CONTENT',
  ].includes(invalidationPageType);
  const contentLanguageRegExp = translateRegExp(contentLanguage);
  const pathRegExp = new RegExp(path, 'i');
  const homePageRegExp = new RegExp(`${contentLanguageRegExp.source}.(json|html)$`, 'i');

  return (entry: string): boolean => {
    if (
      contentLanguageRegExp.test(entry) &&
      pathRegExp.test(entry) &&
      (isMatchGlobal ||
        (isMatchPLP && isPLP(entry)) ||
        (isMatchPDP && entry.match(LEAN_PRODUCT_CODE_REGEXP)) ||
        (invalidationPageType === 'HOME' && homePageRegExp.test(entry)))
    ) {
      return true;
    }

    return false;
  };
}

export const QUBIT_API_URL = `https://sse.qubit.com/v1/gstar_${
  process.env.ENVIRONMENT === Environments.PRD ? 'production' : 'staging'
}/experiences`;

export const EXPERIENCE_IDS = ['240512', '240562'];

type CacheItem = {
  data: QubitResponse;
  expiry: number;
};

const cache: Record<string, CacheItem> = {};

export const fetchQubitData = async (
  experienceIds?: string[],
  contextId?: string,
  isPreview?: boolean
): Promise<QubitResponse> => {
  const defaultResponse = {
    experiencePayloads: [],
    contextId: '',
    cookieDomains: [],
  } as QubitResponse;

  const ids = experienceIds?.length ? experienceIds.join(',') : EXPERIENCE_IDS.join(',');
  const dateNow = new Date().getTime();

  try {
    if (contextId) {
      const cachedData = cache[contextId as string];

      if (cachedData && dateNow < cachedData.expiry) {
        return cachedData.data;
      }
    }

    const query = new URLSearchParams();

    if (contextId) {
      query.set('contextId', contextId);
    }

    query.set('experienceIds', ids);

    const response = await fetch(
      `${QUBIT_API_URL}?${query.toString()}${isPreview ? '&preview' : ''}`
    );

    if (!response.ok) {
      return defaultResponse;
    }

    const data = await response.json();

    if (contextId) {
      cache[contextId as string] = {
        data,
        expiry: dateNow + 1000 * 60 * 60,
        // 1 hour cache expiry to fetch new qubit experience data
      };
    }

    return data.experiencePayloads?.length ? data : defaultResponse;
  } catch (error) {
    return defaultResponse;
  }
};

export const getExperienceOptions = async (
  experienceId: string,
  contextId?: string,
  isPreview?: boolean
): Promise<ExperiencePayload | undefined> => {
  const { experiencePayloads } = await fetchQubitData([experienceId], contextId, isPreview);

  return experiencePayloads?.at(0);
};
