import type { ChangeEvent, ForwardedRef, KeyboardEvent, ReactElement } from 'react';
import { forwardRef, useId } from 'react';
import styled from 'styled-components';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { FieldContainer, FieldLabel, FieldError, FieldSuggestion } from '../styles';
import { media, colors } from '../../styles';
import type { Required, Pattern } from '../types';

export interface InputFieldProps<TFormValues extends FieldValues> {
  id?: string;
  disabled?: boolean;
  label?: string;
  name: Path<TFormValues>;
  type?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  required?: Required;
  pattern?: Pattern;
  register?: UseFormRegister<TFormValues>;
  setValue?: (name: string, value: unknown) => void;
  onFocus?: () => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  showError?: boolean;
  showSuggestion?: boolean;
  setShowSuggestion?: (suggested: boolean) => void;
  suggestionText?: string;
  suggestionValue?: string;
  separate?: boolean;
  testId?: string;
  [k: string]: unknown;
  value?: string;
  role?: string;
  ariaExpanded?: boolean;
}

const S = {
  InputWrapper: styled.div``,

  Input: styled.input<{ $error: string }>`
    height: 48px;
    border-width: 2px;
    font-weight: 700;
    padding: 11px 10px 10px;
    color: ${({ $error }) => ($error ? colors.ERROR_RED : colors.NERO_GREY)};
    font-size: 13px;
    border: 2px solid ${({ $error }) => ($error ? colors.ERROR_RED : colors.NERO_GREY)};
    background: ${({ $error }) => ($error ? colors.ORANGE_YELLOW : colors.WHITE)};
    line-height: normal;
    outline: 0;
    display: inline-block;
    width: 100%;

    &:focus {
      border-color: ${colors.PRUSSIAN_BLUE};
    }

    &::placeholder {
      color: ${colors.ACCESSIBILITY_GREY};
      font-weight: ${({ $error }) => ($error ? 700 : 400)};
    }

    &:focus::placeholder {
      color: ${colors.LIGHT_GREY};
    }

    @media ${media.greaterThan('sm')} {
      height: 40px;
    }
  `,

  FieldError: styled(FieldError)<{ $separate: boolean }>`
    @media ${media.greaterThan('sm')} {
      padding-left: ${({ $separate }) => ($separate ? '26%' : 0)};
    }
  `,
  FieldSuggestion: styled(FieldSuggestion)<{ $separate: boolean }>`
    @media ${media.greaterThan('sm')} {
      padding-left: ${({ $separate }) => ($separate ? '26%' : 0)};
    }
  `,
};

const InputFieldComponent = <TFormValues extends FieldValues>(
  {
    id,
    disabled,
    label,
    name,
    type = 'text',
    placeholder = '',
    error = '',
    className = '',
    required,
    pattern,
    register,
    setValue,
    onFocus,
    onBlur,
    value,
    onChange,
    showError,
    showSuggestion,
    setShowSuggestion,
    suggestionText,
    suggestionValue,
    onKeyDown,
    onKeyUp,
    autoComplete,
    separate = false,
    testId = undefined,
    role,
    ariaExpanded,
  }: InputFieldProps<TFormValues>,
  ref?: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const errorMessageId = `error-message${useId()}`;

  return (
    <>
      <FieldContainer className={className}>
        {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
        <S.InputWrapper className="input-field-wrapper">
          <S.Input
            id={id}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            $error={error}
            {...(register ? register(name, { required, pattern }) : { ref })}
            {...(onFocus ? { onFocus } : {})}
            {...(onBlur ? { onBlur } : {})}
            {...(onChange ? { onChange } : {})}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            autoComplete={autoComplete}
            data-testid={error ? `${testId}-error` : testId}
            value={value}
            role={role}
            aria-invalid={error ? true : undefined}
            aria-describedby={error ? errorMessageId : undefined}
            aria-expanded={role === 'combobox' ? ariaExpanded : undefined}
          />
        </S.InputWrapper>
      </FieldContainer>
      {error && showError && (
        <S.FieldError
          id={errorMessageId}
          $separate={separate}
          data-testid={`${testId}-error-message`}
        >
          {error}
        </S.FieldError>
      )}
      {!error &&
        showSuggestion &&
        setShowSuggestion &&
        suggestionText &&
        setValue &&
        suggestionValue && (
          <S.FieldSuggestion variant="body" component="span" $separate={separate}>
            {suggestionText}{' '}
            <strong
              aria-hidden="true"
              onClick={() => {
                setValue('email', suggestionValue);
                setShowSuggestion(false);
              }}
              onKeyDown={() => {
                setValue('email', suggestionValue);
                setShowSuggestion(false);
              }}
            >
              {suggestionValue}
            </strong>
          </S.FieldSuggestion>
        )}
    </>
  );
};

export const InputField = forwardRef(InputFieldComponent);
