// Dynamic Amplience Variables

export const EMAIL_VARIABLE = '[email]';
export const PRIVACY_POLICY_VARIABLE = '[privacy policy]';
export const REMAINING_TIME_VARIABLE = '[remainingTime]';
export const NUMBER_OF_RESULTS_VARIABLE = '[number of results]';
export const PRODUCT_LIST_SEEN_RESULTS_VARIABLE = '[seen results]';
export const PRODUCT_LIST_TOTAL_RESULTS_VARIABLE = '[total results]';
export const PRODUCT_LIST_NEXT_RESULTS_VARIABLE = '[next results]';
export const SEARCH_TERM = '[search term]';
export const COUNTRY_NAME = '[country name]';
