import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { colors, media } from '../../styles';
import type { Required } from '../types';

export interface RadioProps<TFormValues extends FieldValues> {
  id: string;
  label: string;
  name: Path<TFormValues>;
  value?: string;
  error?: string;
  register: UseFormRegister<TFormValues>;
  required?: Required;
  testId?: string;
  defaultChecked?: boolean;
  ariaLabel?: string;
  [k: string]: unknown;
}

const S = {
  RadioLabel: styled.label`
    position: relative;
    display: block;
    margin-bottom: 14px;
    cursor: pointer;
    color: ${colors.NERO_GREY};

    @media ${media.greaterThan('sm')} {
      margin-bottom: 0;
    }
  `,

  Radio: styled.input<{ $error: string }>`
    position: absolute;
    left: -100000px;

    & + span::before {
      content: '';
    }

    &:checked + span::before {
      font-family: gstar-icons;
      content: '\\F15D';
      color: ${colors.PRUSSIAN_BLUE};
    }

    &:focus + span::before {
      border: 2px solid ${({ $error }) => ($error ? colors.ERROR_RED : colors.PRUSSIAN_BLUE)};
    }
  `,

  Label: styled.span<{ $error: string }>`
    display: block;
    width: auto;
    position: relative;
    box-sizing: border-box;
    padding: 5px 10px 5px 34px;
    font-weight: 400;
    color: ${colors.NERO_GREY};
    margin-right: 20px;
    white-space: nowrap;
    font-size: 13px;
    line-height: 22px;

    &::before {
      border-radius: 50%;
      background-clip: padding-box;
      background-color: ${({ $error }) => ($error ? colors.ORANGE_YELLOW : colors.WHITE)};
      border: 2px solid ${({ $error }) => ($error ? colors.ERROR_RED : colors.BORDER_GREY)};
      display: block;
      font-style: normal;
      height: 22px;
      width: 22px;
      line-height: 22px;
      text-align: center;
      margin-top: -2px;
      position: absolute;
      left: 0;
    }
  `,
};

export const Radio = <TFormValues extends FieldValues>({
  id,
  name,
  label,
  error = '',
  value,
  register,
  required,
  testId = undefined,
  defaultChecked = false,
  ariaLabel,
}: RadioProps<TFormValues>): ReactElement => (
  <S.RadioLabel htmlFor={id} data-testid={testId} role="presentation">
    <S.Radio
      type="radio"
      {...register(name, { required })}
      id={id}
      $error={error}
      defaultChecked={defaultChecked}
      value={value}
      role="radio"
      aria-label={ariaLabel ?? label}
    />
    <S.Label $error={error}>{label}</S.Label>
  </S.RadioLabel>
);
