import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { colors } from '../../styles';
import type { Required } from '../types';

export interface CheckboxProps<TFormValues extends FieldValues> {
  label: string | ReactElement;
  name: Path<TFormValues>;
  error?: string;
  className?: string;
  register: UseFormRegister<TFormValues>;
  required?: Required;
  testId?: string;
  isChecked?: 'true' | 'false';
  [k: string]: unknown;
}

const S = {
  CheckboxLabel: styled.label`
    padding: 14px 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    line-height: 24px;
    color: ${colors.NERO_GREY};
  `,

  Checkbox: styled.input<{ $error: string }>`
    position: absolute;
    left: -100000px;

    & + span::before {
      content: '';
    }

    &:checked + span::before {
      font-family: gstar-icons;
      content: '\\F13C';
      color: ${colors.PRUSSIAN_BLUE};
    }

    &:focus + span::before {
      border: 2px solid ${({ $error }) => ($error ? colors.ERROR_RED : colors.PRUSSIAN_BLUE)};
    }
  `,

  Label: styled.span<{ $error: string }>`
    padding: 5px 10px 5px 36px;
    display: flex;
    align-items: center;
    font-weight: 400;
    box-sizing: border-box;
    vertical-align: middle;
    line-height: 1.3;
    font-size: 13px;
    color: ${colors.NERO_GREY};

    &::before {
      margin-top: 0;
      font-size: 18px;
      background-color: ${({ $error }) => ($error ? colors.ORANGE_YELLOW : colors.WHITE)};
      border: 2px solid ${({ $error }) => ($error ? colors.ERROR_RED : colors.BORDER_GREY)};
      display: block;
      font-weight: 400;
      line-height: 20px;
      position: absolute;
      content: '';
      text-align: center;
      width: 22px;
      height: 22px;
      left: 0;
      box-sizing: content-box;
    }
  `,
};

export const Checkbox = <TFormValues extends FieldValues>({
  label,
  name,
  error = '',
  className = '',
  register,
  required,
  testId = undefined,
}: CheckboxProps<TFormValues>): ReactElement => (
  <S.CheckboxLabel className={className} data-testid={testId}>
    <S.Checkbox $error={error} type="checkbox" {...register(name, { required })} />
    <S.Label $error={error}>{label}</S.Label>
  </S.CheckboxLabel>
);
