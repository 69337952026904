import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const CheckIcon = ({ color = colors.BLACK, hoverColor, onClick }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    $hoverColor={hoverColor}
    onClick={onClick}
  >
    <g fill={color}>
      <polygon points="1024,218.733 917,112 330,698.533 107,476.09 0,582.823 330,912 " />
    </g>
  </Svg>
);

export default CheckIcon;
