/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import type { ReactNode } from 'react';
import type {
  Color,
  ProductFibers,
  Signing,
  Wishlist,
  WishlistImageData,
  WishlistPrice,
  WishlistSimplifiedImageData,
} from '../../graphql/codegen';
import { fetchWishlistData } from '../../graphql/queries';
import { useAppContext } from '../static/AppContext';
import { storageAvailable } from '../../helpers';
import { WISHLIST_ENTRIES } from '../../constants/localStorageKeys';

export type WishlistSimplifiedImage = {
  key: string;
  value: WishlistImageData;
};

export type WishlistProduct = {
  baseProductStockLevel?: string;
  code?: string;
  color?: Color;
  discountPercentage?: number;
  displayAlternateHoverImage?: boolean;
  fitEN?: string;
  fromPrice?: WishlistPrice;
  isDiscontinued?: boolean;
  mainColor?: Color;
  materialGroup?: string;
  name?: string;
  price?: WishlistPrice;
  productFiberRanking?: ProductFibers;
  signings?: (Signing | null)[];
  simplifiedImages?: WishlistSimplifiedImage[];
  staticCategoryPath?: string;
  staticCategoryPathIds?: string;
  subTargetAudience?: string;
  url?: string;
  formattedLowestPrice?: string;
  formattedLowestPriceDiscountPercentage?: string;
  formattedOriginalPriceDiscountPercentage?: string;
};

interface WishlistLocalStorageData {
  isFetched: boolean;
  products?: WishlistProduct[];
}

const parseWishlistResponse = (response: Wishlist): WishlistLocalStorageData => {
  const products: WishlistProduct[] | undefined = response.results?.map(p => {
    const imageData: WishlistSimplifiedImage[] = [];

    if (p?.simplifiedImageData) {
      Object.keys(p.simplifiedImageData).forEach(key => {
        const value: WishlistImageData | undefined | null = (
          p.simplifiedImageData
            ? p.simplifiedImageData[key as keyof WishlistSimplifiedImageData]
            : undefined
        ) as WishlistImageData;

        imageData.push({ key, value });
      });
    }

    return {
      baseProductStockLevel: p?.baseProductStockLevelStatus || undefined,
      code: p?.code || undefined,
      color: p?.color || undefined,
      discountPercentage: p?.discountPercentage || undefined,
      displayAlternateHoverImage: p?.displayAlternateHoverImage || undefined,
      fitEN: p?.fitEN || undefined,
      fromPrice: p?.fromPrice || undefined,
      isDiscontinued: p?.isDiscontinued || undefined,
      mainColor: p?.mainColor || undefined,
      materialGroup: p?.materialGroup || undefined,
      name: p?.name || undefined,
      price: p?.price || undefined,
      productFiberRanking: p?.productFiberRanking || undefined,
      signings: p?.signings || undefined,
      simplifiedImages: imageData,
      staticCategoryPath: p?.staticCategoryPath || undefined,
      staticCategoryPathIds: p?.staticCategoryPathIds || undefined,
      subTargetAudience: p?.subTargetAudience || undefined,
      url: p?.url || undefined,
      formattedLowestPrice: p?.formattedLowestPrice || undefined,
      formattedLowestPriceDiscountPercentage:
        p?.formattedLowestPriceDiscountPercentage || undefined,
      formattedOriginalPriceDiscountPercentage:
        p?.formattedOriginalPriceDiscountPercentage || undefined,
    };
  });

  return {
    isFetched: true,
    products,
  };
};

type WishlistContextType = {
  miniWishlist: WishlistLocalStorageData | undefined;
  setMiniWishlist: (miniWishlist: WishlistLocalStorageData) => void;
  fetchWishlist: (forceUpdate: boolean) => void;
};

const WishlistContext = createContext<WishlistContextType>({
  miniWishlist: undefined,
  setMiniWishlist: () => {},
  fetchWishlist: () => {},
});

export const useWishlistContext = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }: { children: ReactNode }) => {
  const [miniWishlist, setMiniWishlist] = useState<WishlistLocalStorageData>();
  const { locale } = useAppContext();

  const fetchWishlist = useCallback(
    async (forceUpdate: boolean) => {
      const isLocalStorageAvailable = storageAvailable('localStorage');
      const localWishlistData = isLocalStorageAvailable
        ? localStorage.getItem(`${WISHLIST_ENTRIES}_${locale}`)
        : '';

      if (!forceUpdate && localWishlistData) {
        setMiniWishlist(JSON.parse(localWishlistData));
      } else {
        const wishlistData = await fetchWishlistData(locale);

        if (wishlistData.wishlist) {
          const parsedData = parseWishlistResponse(wishlistData.wishlist);

          setMiniWishlist(parsedData);

          if (isLocalStorageAvailable) {
            localStorage.setItem(`${WISHLIST_ENTRIES}_${locale}`, JSON.stringify(parsedData));
          }
        }
      }
    },
    [locale]
  );

  useEffect(() => {
    fetchWishlist(false);
  }, [fetchWishlist]);

  const value = useMemo(
    () => ({
      miniWishlist,
      setMiniWishlist,
      fetchWishlist,
    }),
    [fetchWishlist, miniWishlist]
  );

  return <WishlistContext.Provider value={value}>{children}</WishlistContext.Provider>;
};
