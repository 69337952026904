export const isTouchable = () => {
  if (window) {
    return 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;
  }

  return false;
};

export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

function getAndroidVersion() {
  const { userAgent } = navigator;
  const match = userAgent.match(/Android\s([0-9.]*)/);

  return match ? match[1] : false;
}

function getIosVersion() {
  const { userAgent } = navigator;

  return (
    parseFloat(
      `${(/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(userAgent) || [0, ''])[1]}`
        .replace('undefined', '3_2')
        .replace('_', '.')
        .replace('_', '')
    ) || false
  );
}

const devices = {
  ANDROID: 'Android',
  IOS: 'iOS',
  OSX: 'Mac OS',
  WINDOWS: 'Windows',
  LINUX: 'Linux',

  CHROME: 'Chrome',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  IE: 'Internet Explorer',
  OPERA: 'Opera',
  SAFARI: 'Safari',

  UNKNOWN: 'Unknown Browser',
};

// https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
export const getOS = () => {
  const { userAgent } = navigator;
  const platform = navigator.userAgentData?.platform || navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = devices.OSX;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = devices.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = devices.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = devices.ANDROID;
  } else if (/Linux/.test(platform)) {
    os = devices.LINUX;
  }

  return os;
};

// https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
export const getBrowser = () => {
  const { userAgent } = navigator;

  let isChrome = userAgent.indexOf('Chrome') > -1;
  const isIE = userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('rv:') > -1;
  const isFirefox = userAgent.indexOf('Firefox') > -1;
  let isSafari = userAgent.indexOf('Safari') > -1;
  const isOpera = userAgent.indexOf('OP') > -1;
  const isEdgeChromium = userAgent.indexOf('Edg') > -1;

  if (isChrome && isSafari) {
    isSafari = false;
  }

  if (isChrome && isOpera) {
    isChrome = false;
  }

  let browser = devices.UNKNOWN;

  if (isChrome) {
    browser = devices.CHROME;
  } else if (isIE) {
    browser = devices.IE;
  } else if (isFirefox) {
    browser = devices.FIREFOX;
  } else if (isSafari) {
    browser = devices.SAFARI;
  } else if (isOpera) {
    browser = devices.OPERA;
  } else if (isEdgeChromium) {
    browser = devices.EDGE;
  }

  return browser;
};

export const getOsVersion = () => {
  const { userAgent } = navigator;

  if (userAgent.match(/iPhone|iPad|iPod/i)) {
    return getIosVersion();
  }

  if (userAgent.indexOf('Android') !== -1) {
    return getAndroidVersion();
  }

  if (navigator.platform.match(/win/i)) {
    if (userAgent.match(/NT 6\.1/)) {
      return 7;
    }

    if (userAgent.match(/NT 6\.2/)) {
      return 8;
    }

    if (userAgent.match(/NT 6\.3/)) {
      return 8.1;
    }

    if (userAgent.match(/NT 5/)) {
      // Vista or XP
      return 6;
    }

    // 10 or higher
    return 10;
  }

  return devices.UNKNOWN;
};

export const getBrowserVersion = () => {
  const { userAgent } = navigator;

  let matches;

  if (getBrowser() === devices.SAFARI) {
    if (navigator.platform === 'MacIntel') {
      matches = navigator.appVersion.match(/Version\/([0-9.]+)/);

      return (matches && matches[1]) || devices.UNKNOWN;
    }

    return getOsVersion();
  }

  if (userAgent.indexOf('Android') !== -1 || userAgent.match(/iPhone|iPad|iPod/i)) {
    return getOsVersion();
  }

  if (userAgent.indexOf('Edge/') !== -1) {
    matches = userAgent.match(/Edge\/([0-9.]+)/);

    return matches ? matches[1] : '12';
  }

  if (userAgent.indexOf('MSIE') !== -1 || userAgent.match(/Trident/i)) {
    matches = userAgent.match(/MSIE ([0-9]+)/);

    let minVersion = '9';

    if (!matches) {
      matches = userAgent.match(/rv:([0-9]+)/);
      minVersion = '10';
    }

    if (matches) {
      return parseInt(matches[1], 10) || minVersion;
    }
  }

  return devices.UNKNOWN;
};

export const isMobileUA = () => {
  const userAgentOS = getOS();

  return [devices.IOS, devices.ANDROID].includes(userAgentOS);
};
