// Product Tile Type for data layer event
export const PRODUCT_TILE_TYPE = 'productTileType';
export const CTL = 'recommendationshybris';
export const YMAL = 'recommendations';
export const YMAL_PLP = 'recommendations_PLP';
export const YMAL_SEARCH = 'recommendations_SEARCH';
export const YMAL_PDP = 'recommendations_PDP';
export const YMAL_HP = 'recommendations_HP';
// To be configured below. Added for type reference of getYMALTileType() function in dataLayer.ts
export const YMAL_CAMPAIGN = 'recommendations_CAMPAIGN';
export const YMAL_STORIES = 'recommendations_STORIES';
export const RV = 'recentlyviewed';
export const PLP = 'plp';
export const SRP = 'searchresults';
