import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { PLPSystemTexts } from '../../../amplienceTypes/schemas/imported/plp-system-texts-schema';
import type { CategoryDetails } from '../../graphql/codegen';
import type { PLPPage } from '../../../amplienceTypes/schemas/exported/plp-schema';
import type { PLPSlotContent } from '../../../types/shop';
import { useMediaQueryContext } from '../dynamic/MediaQueryContext';
import type { StrictBreakpoint } from '../dynamic/MediaQueryContext';

type PageContent = PLPSlotContent & PLPPage;

type ProductListContextProps = {
  systemTexts?: PLPSystemTexts | null;
  categoryDetails?: CategoryDetails;
  pageContent?: PageContent;
  isPLP?: boolean;
};

type ProductListContextState = {
  /**
   * An array of filtered and ordered injected content for the lister
   */
  filteredInjectedContent?: PageContent['contentInjections'];
  /**
   * The amount of lister tiles taken by content injection
   *
   * Returns the correct number according to the current breakpoint
   */
  contentSize?: number;
  /**
   * A list of facets that should be rendered for the current PLP/category
   */
  visibleFacetsList: Set<Maybe<string> | undefined>;
};

type ProductListContextValue = ProductListContextProps & ProductListContextState;

const ProductListContext = createContext<ProductListContextValue>({
  systemTexts: undefined,
  categoryDetails: undefined,
  pageContent: undefined,
  isPLP: undefined,
  filteredInjectedContent: undefined,
  contentSize: 0,
  visibleFacetsList: new Set(),
});

export const useProductListContext = () => useContext(ProductListContext);

export const ProductListContextProvider = ({
  systemTexts,
  categoryDetails,
  pageContent,
  isPLP,
  children,
}: PropsWithChildren<ProductListContextProps>) => {
  const { contentInjections } = pageContent ?? {};
  const { breakpoint } = useMediaQueryContext();
  const bp = breakpoint as StrictBreakpoint;

  const visibleFacetsList = useMemo(
    () => new Set(categoryDetails?.visibleFacets?.map(facet => facet?.code)),
    [categoryDetails]
  );

  const filteredInjectedContent = useMemo(() => {
    const takenContentSpots: Record<StrictBreakpoint, Set<number>> = {
      mobile: new Set(),
      tablet: new Set(),
      desktop: new Set(),
    };

    return (
      contentInjections
        /* check if there is more than one content injection on the same position, first one should win */
        ?.filter(({ content, position }) => {
          const breakpointSet = takenContentSpots[bp];
          const start = position?.[bp] ?? 0;
          const end = start + (content?.size?.[bp] ?? 0);

          for (let i = start; i < end; i++) {
            if (breakpointSet.has(i)) {
              return false;
            }

            breakpointSet.add(i);
          }

          return true;
        })
        /* sorting is required to keep the correct content order in the DOM */
        ?.sort((a, b) => {
          const first = (a.position?.[bp] ?? 0) + (a.content?.size?.[bp] ?? 0);
          const second = (b.position?.[bp] ?? 0) + (b.content?.size?.[bp] ?? 0);

          return first - second;
        })
    );
  }, [bp, contentInjections]);

  const contentSize = useMemo(() => {
    const record: Record<StrictBreakpoint, number> = {
      mobile: 0,
      tablet: 0,
      desktop: 0,
    };

    filteredInjectedContent?.forEach(({ content }) => {
      record[bp] += content?.size?.[bp] ?? 0;
    });

    return record;
  }, [bp, filteredInjectedContent]);

  const breakPointContentSize = contentSize[bp];

  const value = useMemo<ProductListContextValue>(
    () => ({
      systemTexts,
      categoryDetails,
      pageContent,
      isPLP,
      filteredInjectedContent,
      contentSize: breakPointContentSize,
      visibleFacetsList,
    }),
    [
      breakPointContentSize,
      categoryDetails,
      filteredInjectedContent,
      isPLP,
      pageContent,
      systemTexts,
      visibleFacetsList,
    ]
  );

  return <ProductListContext.Provider value={value}>{children}</ProductListContext.Provider>;
};
