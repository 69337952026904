/* eslint-disable @typescript-eslint/no-empty-function */
import { getCookie } from 'cookies-next';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { COCO, IMANRUT, RSU } from '../../constants/cookies';

type CookieContextType = {
  /**
   * `Identify Me As Non-Real-User-Traffic`
   *
   * can be also set as query param `?imanrut=true'
   */
  imanrut: boolean;
  /**
   * `Randomly Selected UserID`
   *
   * UUID without dashes
   */
  rsu: string | undefined;
  /**
   * `Consent Cookie`
   */
  cocoCookie?: string | boolean | null;
  /**
   * setter for `Consent Cookie`
   */
  setCocoCookie: (value?: string | boolean | null) => void;
};

const CookieContext = createContext<CookieContextType>({
  rsu: '',
  imanrut: false,
  cocoCookie: undefined,
  setCocoCookie: () => {},
});

export const useCookieContext = () => useContext(CookieContext);

export const CookieProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const imanrutValue = router.query?.imanrut || getCookie(IMANRUT);
  const [cocoCookie, setCocoCookie] = useState(getCookie(COCO));
  const rsu = getCookie(RSU)?.toString();

  let imanrut = false;

  if (imanrutValue) {
    if (typeof imanrutValue === 'boolean') {
      imanrut = imanrutValue;
    } else if (Array.isArray(imanrutValue)) {
      imanrut = JSON.parse(imanrutValue?.at(0) || 'false');
    } else {
      imanrut = JSON.parse(imanrutValue);
    }
  }

  useEffect(() => {
    const coco = getCookie(COCO) as string;

    setCocoCookie(coco as string);
  }, []);

  const value = useMemo(
    () => ({
      rsu,
      imanrut,
      cocoCookie,
      setCocoCookie,
    }),
    [cocoCookie, imanrut, rsu]
  );

  return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>;
};
