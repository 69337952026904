/* eslint-disable camelcase */
import { getCookie } from 'cookies-next';
import type { SectionName } from '../../amplienceTypes/schemas/imported/product-recommendations-schema';
import type { VendorEvent } from '../../pages/_app';
import {
  CTL,
  PRODUCT_TILE_TYPE,
  RV,
  YMAL,
  YMAL_PLP,
  YMAL_PDP,
  YMAL_SEARCH,
  YMAL_HP,
  PLP,
  SRP,
  YMAL_CAMPAIGN,
  YMAL_STORIES,
} from '../constants/productTileTypes';
import type {
  CartEntryFieldsFragment,
  CartMutation,
  CartProduct,
  Country,
  Maybe,
  Product,
} from '../graphql/codegen';
import { fetchCommonData } from '../graphql/queries';
import { storageAvailable } from '../helpers';
import type { ViewAsType } from '../context/dynamic/ImageSwitchContext';
import { PRODUCT_CODE_REGEXP } from '../constants/regex';
import { COCO, RSU } from '../constants/cookies';
import { PageTypes } from '../constants';

export interface EventProduct {
  name?: string | null;
  materialNumber?: string | null;
  materialGroup?: string | null;
  ecomm_prodid?: string | null;
  staticCategoryPath?: string | null;
  staticCategoryPathIds?: string | null;
  styleFamily?: string | null;
  mainColor?: string | null;
  mainColorHex?: string | null;
  ean?: string | null;
  comingSoon?: boolean | null;
  programme?: string | null;
  size?: string | null;
  fit?: string | null;
  viewType?: string | null;
  availability?: string | null;
  fromPrice?: number | null;
  basePrice?: number | null;
  finalPrice?: number | null;
  sale?: string | null;
  subTargetAudience?: string | null;
  quantity?: number | null;
  removalType?: string | null;
  position?: number | null;
}

export type SearchTrackingType = 'click' | 'enter' | 'page loaded';

type EventType =
  | 'eecProductDetailView'
  | 'eecAddToCart'
  | 'eecRemoveFromCart'
  | 'eecProductClick'
  | 'eecImpressions';

const parseProduct = (product: Product, fromProductList = false) => {
  const {
    stockNotificationMessageEN,
    price,
    ean,
    code,
    fitEN,
    fromPrice,
    mainColor,
    materialGroup,
    baseProduct,
    nameEN,
    programEN,
    size,
    staticCategoryPath,
    staticCategoryPathIds,
    subTargetAudience,
    baseProductStockNotificationMessageEN,
    styleFamilyEN,
    oneSize,
    isGiftCard,
    sizeInformation,
  } = product;
  const sale = fromPrice?.value !== price?.value;
  const fromPriceDl = sale ? { fromPrice: fromPrice?.value } : {};

  const prodid = code?.toUpperCase() || baseProduct?.toUpperCase();
  let ecommProdid = {};
  let sizeValue = {};
  let eanValue = {};

  if (oneSize || isGiftCard) {
    // sizeInformation is not available on PLP/SRP but available on PDP
    // ean on PDP is available only when accessing the onesize product as product variant
    // for PDP(solr) base product - sizeInformation?.sizes?.at(0).ean
    // for PDP(solr) variant product - ean
    // for PLP/SRP(algolia) - ean
    const oneSizeEan = sizeInformation?.sizes?.at(0)?.ean ?? ean ?? null;

    ecommProdid = { ecomm_prodid: (!baseProduct ? `${code}-PC` : code)?.toUpperCase() };
    sizeValue = { size: 'PC' };
    eanValue = oneSizeEan ? { ean: oneSizeEan } : {};
  } else if (!fromProductList) {
    ecommProdid = size?.value ? { ecomm_prodid: prodid } : {};
    eanValue = ean ? { ean } : {};
    sizeValue = size?.value ? { size: size.value?.toUpperCase() } : {};
  }

  return {
    availability: baseProductStockNotificationMessageEN || stockNotificationMessageEN || 'unknown',
    basePrice: price?.value,
    ...eanValue,
    ...ecommProdid,
    finalPrice: price?.value,
    fit: fitEN,
    ...fromPriceDl,
    mainColor: mainColor?.descriptionEN ?? mainColor?.description,
    materialGroup,
    materialNumber: baseProduct?.toUpperCase() || code?.toUpperCase(),
    name: nameEN,
    programme: programEN,
    sale: sale.toString(),
    ...sizeValue,
    staticCategoryPath,
    staticCategoryPathIds,
    styleFamily: styleFamilyEN,
    subTargetAudience,
  };
};

const makeEventObject = (eventType: EventType) => ({
  events: {
    category: 'ecommerce',
    action: eventType,
    label: undefined,
  },
  event: eventType,
});

const getYMALTileType = (pageType: PageTypes = PageTypes.OTHER) => {
  const ymalTileTypes: Record<PageTypes, string> = {
    [PageTypes.CATEGORY]: YMAL_PLP,
    [PageTypes.SEARCH]: YMAL_SEARCH,
    [PageTypes.PRODUCT]: YMAL_PDP,
    [PageTypes.HOME]: YMAL_HP,
    [PageTypes.OTHER]: YMAL,
    [PageTypes.CAMPAIGN]: YMAL_CAMPAIGN,
    [PageTypes.STORIES]: YMAL_STORIES,
  };

  return ymalTileTypes[pageType] || YMAL;
};

export const getProductDataLayerEvent = (
  type: EventType,
  data: VendorEvent,
  isQuickView = false,
  quickViewProductTileType = ''
) => {
  let dataLayerEvent: VendorEvent | null = null;

  switch (type) {
    case 'eecAddToCart': {
      const eventData = data as { product: Product; quantity: number };
      const dataLayerData = parseProduct(eventData.product);

      dataLayerEvent = {
        ecommerce: {
          add: {
            products: [
              {
                ...dataLayerData,
                quantity: eventData.quantity,
                viewType: 'general view',
              },
            ],
          },
          currencyCode: eventData.product.price?.currencyIso,
        },
        ...makeEventObject(type),
      };

      break;
    }
    case 'eecRemoveFromCart': {
      const eventData = data as CartEntryFieldsFragment;
      const cartProduct = eventData.product as Product;

      if (cartProduct) {
        const dataLayerData = parseProduct(cartProduct);

        dataLayerEvent = {
          ecommerce: {
            remove: {
              products: [
                {
                  ...dataLayerData,
                  quantity: eventData?.quantity,
                  removalType: 'user',
                },
              ],
            },
            currencyCode: cartProduct.price?.currencyIso,
          },
          ...makeEventObject(type),
        };
      }

      break;
    }
    case 'eecProductDetailView': {
      const eventData = data as Product;
      const dataLayerData = parseProduct(eventData);

      let productTileType = quickViewProductTileType;
      const materialNumber = eventData.code || eventData.baseProduct;

      if (!isQuickView && storageAvailable('localStorage')) {
        productTileType =
          localStorage.getItem(`${PRODUCT_TILE_TYPE}-${materialNumber.toUpperCase()}`) || '';
      }

      dataLayerEvent = {
        ecommerce: {
          currencyCode: eventData.price?.currencyIso,
          productTileType,
          detail: {
            products: [
              {
                ...dataLayerData,
                mainColorHex: eventData.mainColor?.facetColorStyle,
                comingSoon: eventData.comingSoon,
                viewType: isQuickView ? 'quick view' : 'general view',
                hasReviews: eventData.isProductHasReview,
              },
            ],
          },
        },
        ...makeEventObject(type),
      };

      if (storageAvailable('localStorage')) {
        localStorage.removeItem(`${PRODUCT_TILE_TYPE}-${materialNumber.toUpperCase()}`);
      }

      break;
    }
    case 'eecProductClick': {
      const eventData = data as {
        product: Product;
        position: number;
        sectionName: SectionName | 'plp' | 'srp';
        viewType: 'general view' | 'quick view';
      };
      const dataLayerData = parseProduct(eventData.product);
      let tileType = CTL;

      if (eventData.sectionName === 'you may also like') {
        tileType = getYMALTileType(data?.pageType);
      } else if (eventData.sectionName === 'recently viewed') {
        tileType = RV;
      } else if (eventData.sectionName === 'plp') {
        tileType = PLP;
      } else if (eventData.sectionName === 'srp') {
        tileType = SRP;
      }

      const materialNumber = eventData.product.code || eventData.product.baseProduct;

      if (storageAvailable('localStorage')) {
        localStorage.setItem(`${PRODUCT_TILE_TYPE}-${materialNumber.toUpperCase()}`, tileType);
      }

      const productData = eventData.product;
      const isOneSize = productData.oneSize || productData.isGiftCard;
      const ean = productData.sizeInformation?.sizes?.at(0)?.ean || productData.ean;
      const productId = !productData.baseProduct ? `${productData.code}-PC` : productData.code;

      dataLayerEvent = {
        ecommerce: {
          productTileType: tileType,
          currencyCode: productData.price?.currencyIso,
          click: {
            products: [
              {
                ...dataLayerData,
                comingSoon: productData.comingSoon,
                mainColorHex: productData.mainColor?.facetColorStyle,
                position: eventData.position + 1,
                viewType: eventData.viewType,
                availability: productData.baseProductStockNotificationMessageEN,
                ean: isOneSize ? ean : undefined,
                size: isOneSize ? 'PC' : undefined,
                ecomm_prodid: isOneSize ? productId : undefined,
              },
            ],
          },
        },
        ...makeEventObject(type),
      };

      break;
    }
    case 'eecImpressions': {
      const eventData = data as {
        eventProducts: EventProduct[];
        sectionName: SectionName | 'plp' | 'srp';
        currency?: string | null;
      };
      let tileType = CTL;

      if (eventData.sectionName === 'you may also like') {
        tileType = getYMALTileType(data?.pageType);
      } else if (eventData.sectionName === 'recently viewed') {
        tileType = RV;
      } else if (eventData.sectionName === 'plp') {
        tileType = PLP;
      } else if (eventData.sectionName === 'srp') {
        tileType = SRP;
      }

      dataLayerEvent = {
        ecommerce: {
          productTileType: tileType,
          currencyCode: eventData.currency,
          impressions: {
            products: eventData.eventProducts,
          },
        },
        ...makeEventObject(type),
      };

      break;
    }
    default:
      break;
  }

  return dataLayerEvent;
};

export const parseProductForImpressions = (
  product: Product,
  position: number,
  fromProductList = false
) => {
  const dataLayerData = parseProduct(product, fromProductList);

  return {
    ...dataLayerData,
    comingSoon: product.comingSoon,
    mainColorHex: product.mainColor?.facetColorStyle,
    position: position + 1,
  };
};

export const parsePageInfoDataLayer = (
  locale: string,
  language: string,
  slice: string | null | undefined,
  defaultCurrency: string | null | undefined,
  countryEn: Country,
  pageType?: PageTypes
) => {
  const correlationId = getCookie('_rsu') as string | null | undefined;
  const languageFullEn = countryEn.supportedLanguages?.find(
    ln => ln?.isocode?.toLowerCase() === locale
  )?.name;
  const page: { [k: string]: boolean | string | number | undefined | null } = {
    slice,
    viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    viewportHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    currencyCode: defaultCurrency,
    locale,
    country: countryEn?.name,
    language: languageFullEn,
    countryCode: countryEn.isocode,
    languageCode: language,
    pageType: pageType || PageTypes.OTHER,
    timestamp: Date.now() / 1e3,
    correlationId,
  };

  Object.keys(page).forEach(key => {
    if (page[key] === null || page[key] === undefined) {
      delete page[key];
    }
  });

  return page;
};

export const parseUserInfoDataLayer = async (locale: string) => {
  try {
    const consentLevel = getCookie(COCO) as string | null | undefined;
    const response = await fetchCommonData(locale);
    const user: { [k: string]: boolean | string | undefined | null } = {
      assistedSaleUserType: response?.assistedSaleUserType,
      assistedSaleUserID: response?.assistedSaleUserID,
      internal: response?.internal,
      loggedInStatus: response?.loggedInStatus,
      gender: response?.gender,
      customerType: response?.customerType,
      userID: response?.hashedUserId,
      accountType: response?.accountType?.toLowerCase(),
      ageGroup: response?.ageGroup,
      consentLevel: consentLevel || '00000',
      hashedIp: response?.hashedIp,
      thresholdCustomerType: response?.thresholdCustomerType,
    };

    Object.keys(user).forEach(key => {
      if (user[key] === null || user[key] === undefined) {
        delete user[key];
      }
    });

    return user;
  } catch (error) {
    return {};
    // TODO error handling
  }
};

export const getProductSize = (code: string): string => {
  const match = code.match(PRODUCT_CODE_REGEXP);

  return match && match[4] ? match[4].toUpperCase() : '';
};

export const parseCartUpdateDataLayer = (
  action: 'add' | 'remove',
  cartData: CartMutation,
  cartProduct?: Maybe<CartProduct>
) => {
  let modifiedProduct: Maybe<CartProduct> | undefined;
  let entriesProperties = {};
  let basicProperties = {};

  if (action === 'add' && cartData.cart && cartData.cartModifications) {
    modifiedProduct = cartData?.cartModifications[0]?.entry?.product;
  } else if (action === 'remove' && cartProduct) {
    modifiedProduct = cartProduct;
  }

  if (modifiedProduct) {
    const rsu = getCookie(RSU)?.toString() || '';
    const timestamp = Date.now() / 1e3;
    const event_uid = `web_cart_update_${rsu}_${timestamp}`;

    const {
      stockNotificationMessageEN,
      price,
      fromPrice,
      ean,
      fitEN,
      mainColor,
      materialGroup,
      baseProduct,
      code,
      styleFamilyEN,
      nameEN,
    } = modifiedProduct;

    const parentIDPattern = /^(\w+)-(\w+)/;
    const match = baseProduct?.match(parentIDPattern);
    const parent_id = match ? `${match[1]}-${match[2]}`.toLowerCase() : '';

    basicProperties = {
      action,
      availability_en: stockNotificationMessageEN,
      base_price_local_currency: price?.value,
      cart_update_type: 'user',
      domain: window.location.origin,
      ean,
      event_uid,
      fit_en: fitEN,
      from_price_local_currency: fromPrice?.value,
      local_currency: price?.currencyIso,
      main_color_en: mainColor?.descriptionEN || mainColor?.description,
      material_group: materialGroup,
      page_type: 'product',
      parent_id,
      product_id: baseProduct?.toLowerCase(),
      size: getProductSize(code),
      style_family_en: styleFamilyEN,
      timestamp,
      total_price_local_currency: cartData.cart?.totalPrice?.value,
      total_quantity: cartData.cart?.totalItems,
      variant_id: code,
      variant_name: nameEN,
    };
  }

  if (cartData.cart?.entries) {
    type ParsedProducts = {
      [k: string]: number;
    };

    const parsedProducts = cartData.cart.entries.reduce((prev, current) => {
      const parsed = prev;

      if (current?.product?.baseProduct) {
        const productID = current?.product?.baseProduct.toLowerCase();

        if (prev[productID]) {
          parsed[productID] = prev[productID] + (current.quantity || 0);
        } else {
          parsed[productID] = current.quantity || 0;
        }
      }

      return parsed;
    }, {} as ParsedProducts);

    const variantProducts = cartData.cart.entries.map(entry => ({
      variant_id: entry?.product?.code.toLowerCase(),
      quantity: entry?.quantity,
    }));

    entriesProperties = {
      product_ids: Object.keys(parsedProducts),
      product_list: Object.entries(parsedProducts).map(([product_id, quantity]) => ({
        product_id,
        quantity,
      })),
      variant_ids: variantProducts.map(variantProduct => variantProduct.variant_id),
      variant_list: variantProducts,
    };
  }

  return {
    ...basicProperties,
    ...entriesProperties,
  };
};

const trackingOptions: {
  [key in SearchTrackingType]: {
    action: string;
    event: string;
  };
} = {
  click: { action: 'click', event: 'search-click' },
  enter: { action: 'enter', event: 'search-enter' },
  'page loaded': { action: 'page loaded', event: 'searchpageload' },
};

export const parseInternalSearchDataLayer = (
  option: SearchTrackingType,
  searchTerm: string,
  suggestion?: string
) => ({
  events: {
    category: 'search',
    action: trackingOptions[option].action,
    label: suggestion,
  },
  page: {
    searchTerm,
  },
  event: trackingOptions[option].event,
});

export const parseProductListViewAsDataLayer = (viewAs: ViewAsType) => ({
  events: {
    category: 'view as',
    action: 'click',
    label: viewAs === 'outfit' ? 'outfit' : 'product',
  },
  event: 'viewas-click',
});

export const parseSortingOptionsDataLayer = (label: string) => ({
  events: {
    category: 'sorting options',
    action: 'click',
    label,
  },
  event: 'sortingoption-click',
});

type LocaleSelectorAction = 'open' | 'close' | 'submit';

export const parseLocaleSelectorDataLayer = (
  action: LocaleSelectorAction,
  redirectPath?: string
) => ({
  events: {
    category: 'select_locale',
    action,
  },
  event: 'select_locale',
  eventCallback: () => {
    if (redirectPath) {
      window.location.href = redirectPath;
    }
  },
});
