/* 
  Push exit intent banner to Contentsquare
  So the analytics manager can thack when customers see the exit intent banner popup
  Reference: https://g-star.atlassian.net/wiki/spaces/COS/pages/2627666170/Exit+Intent+banner
*/

/* eslint-disable no-underscore-dangle */
function pushEvent(eventName: string, payload: string) {
  window._uxa = window._uxa || [];
  window._uxa.push([eventName, payload]);
}

/**
 * Emits exit intent banner modal displayed event
 */
export function pushExitIntentBannerContentsquare() {
  pushEvent('trackPageEvent', 'View | Exit Intent Banner');
}
