import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import type {
  ProductQuery,
  VisibleProductsQuery,
  CoreProductInfoQuery,
  CountryQuery,
  CartQuery,
  ConfigurationQuery,
  NavigationQuery,
  NavigationTypeParam,
  SocialMediaChannelsQuery,
  CommonDataQuery,
  LocalStoreStockQuery,
  ProductsQuery,
  WishlistQuery,
  CategoryPathsQuery,
  CategoryDetailsQuery,
} from '../codegen';
import {
  ProductDocument,
  VisibleProductsDocument,
  CoreProductInfoDocument,
  CountryDocument,
  CartDocument,
  ConfigurationDocument,
  NavigationDocument,
  SocialMediaChannelsDocument,
  CommonDataDocument,
  LocalStoreStockDocument,
  ProductsDocument,
  WishlistDocument,
  CategoryPathsDocument,
  CategoryDetailsDocument,
} from '../codegen';
import { getClient } from '../apolloClient';
import { createQueryParams } from '../../parsers';
import logger from '../../logger';

const isBuildPhase = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;

const apolloClient = getClient();

class ApolloClientError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export const fetchVisibleProducts = async (
  pageSize: number,
  page: number,
  locale: string
): Promise<VisibleProductsQuery> =>
  new Promise((resolve, reject) => {
    const { baseSiteId } = createQueryParams(locale);

    apolloClient
      .query<VisibleProductsQuery>({
        query: VisibleProductsDocument,
        variables: {
          baseSiteId,
          pageSize,
          page,
          locale,
        },
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });

export const fetchProduct = async (code: string, locale: string, toRefetch?: boolean) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data, errors } = await apolloClient.query<ProductQuery>({
      query: ProductDocument,
      fetchPolicy: !isBuildPhase && toRefetch ? 'network-only' : 'cache-first',
      variables: {
        baseSiteId,
        code,
        locale,
      },
    });

    return { ...data, errors };
  } catch (error) {
    throw new ApolloClientError(`Product - ${error}`);
  }
};

export const fetchProducts = async (locale: string, codes: string[]) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<ProductsQuery>({
      query: ProductsDocument,
      variables: { input: { baseSiteId, codes, locale } },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Products - ${error}`);
  }
};

export const fetchCoreProductInfo = async (code: string, locale: string) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<CoreProductInfoQuery>({
      query: CoreProductInfoDocument,
      variables: {
        baseSiteId,
        code,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Product URL - ${error}`);
  }
};

export const fetchCountry = async (locale: string, isocode: string) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<CountryQuery>({
      query: CountryDocument,
      variables: {
        baseSiteId,
        isocode,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Country - ${error}`);
  }
};

export const fetchCartData = async (locale: string) => {
  try {
    const { data } = await apolloClient.query<CartQuery>({
      query: CartDocument,
      variables: {
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Cart Data - ${error}`);
  }
};

export const fetchWishlistData = async (locale: string) => {
  try {
    const { data } = await apolloClient.query<WishlistQuery>({
      query: WishlistDocument,
      variables: {
        locale,
      },
      fetchPolicy: 'no-cache',
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Wishlist Data - ${error}`);
  }
};

export const fetchConfigurationData = async (locale: string, toRefetch?: boolean) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<ConfigurationQuery>({
      query: ConfigurationDocument,
      fetchPolicy: !isBuildPhase && toRefetch ? 'network-only' : 'cache-first',
      variables: {
        baseSiteId,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Configuration - ${error}`);
  }
};

export const fetchNavigation = async (
  locale: string,
  navigationType: NavigationTypeParam,
  toRefetch?: boolean
) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<NavigationQuery>({
      query: NavigationDocument,
      fetchPolicy: !isBuildPhase && toRefetch ? 'network-only' : 'cache-first',
      variables: {
        baseSiteId,
        navigationType,
        locale,
      },
    });

    return data;
  } catch (error) {
    logger.error(`[Storefront] Error in ${navigationType} navigation data fetch => ${error}`);

    return {};
  }
};

export const fetchSocialMediaChannels = async (locale: string) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<SocialMediaChannelsQuery>({
      query: SocialMediaChannelsDocument,
      variables: {
        baseSiteId,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Social Media Channels - ${error}`);
  }
};

export const fetchCommonData = async (locale: string) => {
  try {
    const { data } = await apolloClient.query<CommonDataQuery>({
      query: CommonDataDocument,
      variables: {
        locale,
      },
    });

    return data.commonData;
  } catch (error) {
    throw new ApolloClientError(`Common Data - ${error}`);
  }
};

export const findStore = async (code: string, isocode: string, locale: string) => {
  try {
    const { data } = await apolloClient.query<LocalStoreStockQuery>({
      query: LocalStoreStockDocument,
      variables: {
        code,
        isocode,
        locale,
      },
      fetchPolicy: 'no-cache',
    });

    return data.localStoreStock;
  } catch (error) {
    throw new ApolloClientError(`Find Store - ${error}`);
  }
};

export const fetchCategoryPaths = async (locale: string) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<CategoryPathsQuery>({
      query: CategoryPathsDocument,
      variables: {
        baseSiteId,
        locale,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Category Paths - ${error}`);
  }
};

export const fetchCategoryDetails = async (locale: string, path: string, toRefetch: boolean) => {
  try {
    const { baseSiteId } = createQueryParams(locale);
    const { data } = await apolloClient.query<CategoryDetailsQuery>({
      query: CategoryDetailsDocument,
      fetchPolicy: !isBuildPhase && toRefetch ? 'network-only' : 'cache-first',
      variables: {
        baseSiteId,
        locale,
        path,
      },
    });

    return data;
  } catch (error) {
    throw new ApolloClientError(`Category Details - ${error}`);
  }
};
